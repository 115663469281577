<template>
  <div @click="hideAllDropdown">
    <BreadCrumb title="Legal Advice" subtitle="">
    </BreadCrumb>
        
    <div class="d-flex justify-content-end">
    <!-- <button class="create_btn" id="create-btn">
      <span class="material-symbols-rounded" style="margin: 0;">add</span>
      <span style="margin: 0 8px;">Create New</span>
      <span class="material-symbols-rounded" style="margin: 0;">keyboard_arrow_down</span>
    </button>
    <b-tooltip
      target="create-btn"
      triggers="click"
      placement="bottom"
      id="dropdown"
      :custom-class="`DROPDOWN-MENU`"
    >
      <div>
        <div
          v-for="(item, index) in dropdownItems"
          :key="index"
          @click="handleSelection(item)"
          class="dropdown-option"
        >
          {{ item }}
        </div>
      </div>
    </b-tooltip> -->

    <!-- <b-dropdown id="menu-dropdown" text="Dropdown Button" no-caret class="m-md-2">
      <b-dropdown-item @click="$bvModal.show('legal-argument')">Legal Argument</b-dropdown-item>
      <b-dropdown-item @click="$bvModal.show('legal-memo')">Legal Memo</b-dropdown-item>
    </b-dropdown> -->

    <b-dropdown offset="13" id="dropdown-button" variant="link" toggle-class="text-decoration-none" no-caret>
      <template #button-content>
        <button class="create_btn" id="create-btn">
        <span class="material-symbols-rounded" style="margin: 0;">add</span>
        <span style="margin: 0 8px;">Create New</span>
        <span class="material-symbols-rounded" style="margin: 0;">keyboard_arrow_down</span>
      </button>
      </template>
      <div style="width:187px;">
        <b-dropdown-item class="m-2" @click="$bvModal.show('legal-argument')">Legal Argument</b-dropdown-item>
        <b-dropdown-item class="m-2" @click="$bvModal.show('legal-memo')">Legal Memo</b-dropdown-item>
      </div>
    </b-dropdown>
  </div>

   <!-- Legal Argument Modal -->
    <b-modal size="lg" id="legal-argument" hide-footer hide-header>
      <argument @close="hideLegalModal" @created="handleCreated" />
    </b-modal>

    <!-- Legal Memo Modal -->
    <b-modal size="lg" id="legal-memo" hide-footer hide-header>
      <memo @close="hideMemoModal" @created="handleCreated"/>
    </b-modal>

    <TableList
      class="table-margin"
      v-if="tableLoading || tableData.length > 0 || $route.query.statement"
      :percentageColumnIndex="6"
      :show-buttons="true"
      :load-data="loadData"
      :loading="tableLoading"
      :tableConfig="tableConfig"
      :tableData="tableData"
      :actions="tableHandler"
      @sort-data="sortData"
      :search="tableSearchConfig"
      @send-query="sendQuery"
      @row-click="navigateToCampaign"
    />

    <div v-else class="empty-placeholder">
      <span class="min-margin"></span>
      <img src="@/assets/misc/empty.svg" alt="No Content"/>
      <p>Welcome to Legal Advice! No Legal Argument/Legal Memo to display yet — start a new one to get started.</p>
      <button
        class="btn-primary"
        @click="navigate('+')"
      >
        <span class="material-symbols-rounded">add</span>
        Add new case
      </button>
    </div>

    <!-- Delete Modal -->
    <b-modal
      id="delete-modal"
      hide-header
      hide-footer
      centered
    >
      <div class="delete-prompt">
        <img src="@/assets/misc/delete.svg" alt="Delete" />
        <p style="text-align: center;">
          <b>Are you sure you want to delete this campaign?</b><br>
          This action is permanent and cannot be undone.
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="$bvModal.hide('delete-modal')">
          Cancel
        </button>
        <button class="btn-danger" @click="deleteCampaign">Delete</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { hide } from '@popperjs/core';
import BreadCrumb from '../../components/misc/BreadCrumb.vue';
import TableList from '../../components/misc/TableList.vue';
import advice from '../../store/advice';
import argument from './Modal/argument.vue';
import memo from './Modal/memo.vue';

export default {
  components: {
    BreadCrumb,
    TableList,
    argument,
    memo,
  },

  data() { return {
    // showLegalArgumentModal: false,
    showLegalMemoModal: false,
    // header
    creds: {},

    // Case Flow Results
    tableLoading: true,
    tableSearchConfig: {
      title: "History",
    },
    tableConfig: {
      format: [
        {
          header: "ID",
          width: 5,
        },
        {
          header: "Statement",
          // width: 34,
        },
        {
          header: "From",
          width: 16,
        },
        {
          header: "Jurisdiction",
          width: 14,
          center: true,
        },
        {
          header: "Date Created",
          width: 16,
          center: true
        },
        {
          header: "Action",
          width: 8,
          center: true,
        },
      ],
      options: [
        {
          icon: "arrow_forward",
          label: "View Campaign",
        },
        {
          icon: "star",
          label: "Bookmark",
        },
        {
          icon: "archive",
          label: "Archive",
        },
        {
          icon: "delete",
          label: "Delete Campaign",
        },
      ],
      pagination: {
        current: 1,
        total: 0,
      },
    },
    fullData: [],
    tableData: [],

    // Dropdown Menu
    selected: null,
    dropdownItems: ['Legal Argument', 'Legal Memo'],
  }},

  watch: {
    // update route when changing page
    tableConfig: {
    handler(newCf) {
      const page = newCf.pagination.current;
      if (this.$route.query.page == page) return;

      const query = { page };
      if (this.$route.query.statement) query.statement = this.$route.query.statement; // Change file_name to statement
      this.$router.push({ query });
    },
    deep: true,
  },

  // update data when changed, works when using backward navigation
    $route() { 
      this.loadData(); 
    }
  },

  methods: {
    // to hide all dropdown menu when clicked on anywhere in the page
    hideAllDropdown() { this.$root.$emit('bv::hide::tooltip'); },

    sortData(data) { this.tableData = data; },

    tableHandler(o, id) {
      this.selected = this.tableData[id][0];
      const campaignId = this.tableData[id][0];
      const campaignType = this.tableData[id][2];

      switch(o) {
        // View File
        case 0: 
          this.viewCampaign(campaignId,campaignType);
          break;

        // Bookmark
        case 1:
          this.bookmarkCampaign();
          break;

        // Archive
        case 2:
          this.archiveCampaign();
          break;

        // Delete
        case 3:
          this.$bvModal.show("delete-modal");
          break;
      }
    },
    hideLegalModal() {
      this.$bvModal.hide("legal-argument");
    },
    hideMemoModal() {
      this.$bvModal.hide("legal-memo");
    },
    handleCreated() {
      this.loadData();
    },
    navigateToCampaign(id) {
      const campaignId = id;
      const campaignType = this.tableData.filter((item) => item[0] === id)[0][2];
      this.viewCampaign(campaignId, campaignType);
    },
    viewCampaign(campaignId, campaignType) {
    let routeName = '';
    let category='';

    if (campaignType === 'Legal Argument') {
      routeName = 'LegalAdvice_ArgumentResult';
      category = 'Legal Argument';
    } else if (campaignType === 'Legal Memo/Opinion') {
      routeName = 'LegalAdvice_MemoResult';
      category = 'Legal Memo';
    }

    this.$router.push({
      name: routeName,
      params: { id: campaignId ,category: category}
    });
    },
    bookmarkCampaign() {
      advice.BookmarkCampaign(this.selected).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    },  
    archiveCampaign() {
      advice
        .ArchiveCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          this.loadData();
        });
    },

    deleteCampaign() {
      this.$bvModal.hide("delete-modal");
      advice
        .DeleteCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          this.loadData();
        });
    },
    handleSelection(item) {
      if (item === 'Legal Argument') {
        this.showLegalArgumentModal = true;
      } else if (item === 'Legal Memo') {
        this.showLegalMemoModal = true;
      }
    },
    loadData() {
      const statement = this.$route.query.statement ?? null; // Change file_name to statement
      const page = this.$route.query.page;

      const obj = { types: ['argument', 'memo'], page };
      if (statement) obj.statement = statement; // Change file_name to statement

      this.tableLoading = true;
      advice
        .GetCampaigns(obj)
        .then(res => {
          if (!res) return;
          const DATA = res.data.data;
          this.tableConfig.pagination = {
            current: DATA.current_page,
            total: DATA.total,
            perPage: DATA.per_page,
          };
          this.fullData = DATA.data;
          this.statLoading = false;
          this.tableLoading = false;

          this.tableData = this.fullData.map(ent => [
            ent.id,
            ent.statement,
            ent.type === "argument" 
            ? "Legal Argument" 
            : ent.type === "memo" 
            ? "Legal Memo/Opinion" 
            : ent.type,
            ent.jurisdiction,
            ent.created_at,
            this.$options.filters.username(ent.creator.name),
            ent.completedPerCentage 
          ]);
        });
    },
    // sendQuery(q) {
    //   const Rquery = this.$route.query;
    //   const query = { page: 1 };
    //   if (q) query.statement = q;
    //   else if (Rquery.page > 1 || Rquery.statement) this.$router.push({ query });
    // },
    sendQuery(q) {
      const Rquery = this.$route.query;
      const query = { page: 1 };
      if (q) {
        query.statement = q; // Change file_name to statement
        this.$router.push({ query });
      } else if (Rquery.page > 1 || Rquery.statement) {
        this.$router.push({ query });
      }
    },
  },

  created() {
    const CRED = JSON.parse(localStorage.getItem("userInfo")).nexlaw_credits;
    this.creds.les = CRED.full.les - CRED.used.les;

    if (!this.$route.query.page) this.$router.push({ query: { page: 1 } });
    else this.loadData();
  },
}
</script>

<style scoped>
.table-margin {
  margin: 16px 10px 0px 10px;
}

.btn-primary {
  height: 40px;

  span {
    margin-right: 10px;
  }
}

.empty-placeholder {
  min-height: 40%;
  width: 500px;

  .min-margin {
    display: inline-block;
    height: 110px;
  }
  
  img {
    margin-top: auto;
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 32px;
  }
}
</style>

<!-- Delete Modal -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt img {
  background-color: var(--interface-grey);
  padding: 32px;
  border-radius: 8px;
  height: 164px;
  width: 100%;
  margin-bottom: 16px;
}

.delete-prompt p {
  margin-bottom: 0px;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
.create_btn{
    border-radius: 4px; 
    background-color:var(--primary);
    color: white; 
    padding: 8px 16px; 
    border: none; 
    cursor: pointer; 
    display: flex; 
    align-items: center;
    margin-right:12px;
}
#dropdown{
  width: 194px !important;
}
</style>