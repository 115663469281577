<template>
 <div>
      <header class="memo-header">
        <div class="title-container">
          <h3 class="main-title">Create Legal Memo/Opinion</h3>
          <p class="subtitle">Draft a precise and compelling legal memo or opinion.</p>
        </div>
        <button class="close-button" aria-label="Close" @click="$emit('close')">
          <span class="material-symbols-rounded close-icon">close</span>
        </button>
      </header>
      <main class="memo-content">
        <form class="memo-form">
          <div class="input-section">
            <label for="question" class="input-label">Question</label>
            <textarea
              id="question"
              class="input-field"
              placeholder="Input question
For example: Did Loman's circular ad for designer leather coat constitute an offer and create a binding contract under United States law?"
              rows="3"
              v-model.trim="question"
              autocorrect="off"
              autocomplete="off"
              v-validate="'required'"
              name="question"
              maxlength="200"
            ></textarea>
            <span class="character-count">{{ getCharCount(question) }}/200</span>
          </div>
          <div class="input-section">
            <label for="findings" class="input-label">Findings</label>
            <textarea
              id="findings"
              v-model.trim="findings"
              autocorrect="off"
              autocomplete="off"
              name="findings"
              v-validate="'required'"
              class="input-field"
              placeholder="Input findings
For example: Loman's Fashions, a retailer of women's and men's outerwear, distributed a circular in November advertising a manufacturer's closeout of designer women's leather coat for $59.99, coats that regularly sold for $300.00. The ad announced that the store would open at 7 am on Friday, November 30, and stated that the 'early bird catches the savings!' After about fifteen minutes, all the advertised coats had been sold."
              rows="6"
              maxlength="500"
            ></textarea>
            <span class="character-count">{{ getCharCount(findings) }}/500</span>
          </div>
          <div class="jurisdiction-section">
            <label class="input-label">Choose Jurisdiction</label>
            <div class="country-list">
              <button
                v-for="(icon, code) in countryIcons"
                :key="code"
                class="country-button"
                :aria-label="`Select ${code}`"
                @click.prevent="selectCountry(code)"
                :class="{ 'selected': jurisdiction === code }"
              >
                <img :src="icon" :alt="`${code} flag`" class="country-flag" />
                <span>{{ code }}</span>
              </button>
            </div>
          </div>
        </form>
      </main>
      <footer class="memo-footer">
        <button class="cancel-button" @click="$emit('close')">Cancel</button>
        <button @click.prevent="generateMemo" :class="question && findings && jurisdiction ? 'generate-button-active' : 'generate-button'">Generate</button>
      </footer>
  </div>
</template>

<script>
import {
  Authenticate,
  groupValidateInput,
} from "./../../../../src/store/utils";
import countryIcons from "./../../../../src/assets/country/index";
import advice from "./../../../store/advice";
export default {
  name: "LegalMemoModal",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      question: "",    
      findings: "",   
      jurisdiction: "",  
      countryIcons,    
      // Define the mapping here
      countryCodeToName: {
        'AUS': 'Australia',
        'USA': 'USA',
        'CAN': 'Canada',
        'NZ': 'New Zealand',
        'SG': 'Singapore',
        'MY': 'Malaysia',
        'UK': 'United Kingdom',
      },
    };
  },
  methods: {
    // Method to calculate char count
    getCharCount(text) { return text.length },

    // Method to handle country selection for jurisdiction
    selectCountry(code) {
      this.jurisdiction = code;
    },

    async generateMemo() {
      try {
        // Validate all fields
        const isValid = await this.$validator.validateAll();
        if (!isValid) {
          this.$toast.error("Please fill in all required fields.");
          return;
        }

        // Group and validate input fields
        const Results = groupValidateInput([
          [this.question, "string", ["max200"]],
          [this.findings, "string", ["max500"]],
          [this.jurisdiction, "jurisdiction"],
        ]);


        // Check if any validation failed
        if (Results.findIndex(x => !x[0]) > -1) {
          const firstError = Results.find(x => !x[0])[1];
          this.$toast.error(`Validation Error: ${firstError}`);
          return;
        }

        // Fetch locations from localStorage
        const locations = localStorage.locations ? JSON.parse(localStorage.locations) : [];

        // Map the jurisdiction code to the full country name
        const jurisdictionCode = Results[2][1];
        const jurisdictionName = this.countryCodeToName[jurisdictionCode];
        // Check if the mapping exists
        if (!jurisdictionName) {
          this.$toast.error("Invalid jurisdiction code selected.");
          return;
        }

        // Find the location by the full country name
        const foundLocation = locations.find(x => x.title === jurisdictionName);

        // Handle case where location is not found
        if (!foundLocation) {
          this.$toast.error("Location not found. Please select a valid jurisdiction.");
          return;
        }

        // Prepare the object to be sent
        const obj = {
          statement: Results[0][1],
          findings: Results[1][1],
          jurisdiction: jurisdictionName, // Use the full country name
          type: "memo",
          answer: "yes", // Verify if this field is required
          location_id: foundLocation.id, // Safely access the id
          language: "en",
        };

        // Create the legal memo/opinion
        await advice.CreateLegalArgumentMemo(obj);
        this.$toast.success("Legal memo/opinion created successfully.");  
        this.$emit('created');
        this.$emit('close');
        Authenticate(this);
        this.$router.push({ name: "LegalAdvice" });

      } catch (error) {
        console.error("Error in generateMemo:", error);
      }
    },
  },
};
</script>
<style scoped>
.memo-header {
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  display: flex;
  width: 100%;
  gap: 40px;
  flex-wrap: wrap;
  padding: 16px 24px;
}

.title-container {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  justify-content: center;
}

.main-title {
  color: #383a3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}

.subtitle {
  color: #86888d !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin: 4px 0 0;
}

.close-button {
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.close-icon {
  width: 24px;
  height: 24px;
  color: #0E4485;
}

.memo-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  justify-content: center;
  padding: 12px 24px 24px;
}

.memo-form {
  width: 100%;
}

.input-section {
  border-radius: 8px;
  border: 1px solid #f2f3f3;
  background-color: #fafbfc;
  padding: 12px;
  margin-bottom: 16px;
}

.input-label {
  color: #383a3e;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.input-field {
  width: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
  color: #86888d;
  font-weight: 400;
  line-height: 24px;
  resize: none;
}

.input-field:focus {
  outline: none;
}

.character-count {
  display: block;
  text-align: right;
  font-size: 14px;
  color: #86888d;
}

.jurisdiction-section {
  border-radius: 8px;
  border: 1px solid #f2f3f3;
  background-color: #fff;
  padding: 8px 16px;
}

.section-title {
  font-size: 16px;
  color: #383a3e;
  font-weight: 500;
  margin: 0 0 16px;
}

.country-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.country-button {
  border-radius: 6px;
  border: 1px solid #f2f3f3;
  background-color: #fafbfc;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 18px;
  font-size: 14px;
  color: #86888d;
  font-weight: 400;
  cursor: pointer;
}
.country-button:hover,.selected{
  border: 1px solid #0E4485;
  background: #E7ECF3;
}

.country-flag {
  width: 24px;
  height: 24px;
}

.memo-footer {
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 16px;
  font: 500 16px Poppins, sans-serif;
}

.cancel-button,
.generate-button {
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid transparent;
}

.cancel-button {
  background-color: #f2f3f3;
  color: #d1d2d5;
}

.generate-button {
  background-color: rgba(14, 68, 133, 0.1);
  color: #d1d2d5;
}

.cancel-button:hover {
  border: 1px solid #D1D2D5;
  color: #383A3E;
}

.generate-button:hover,
.generate-button-active {
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid transparent;
  background: #0E4485;
  color: #fff;
}

@media (max-width: 991px) {
  .memo-header,
  .memo-content {
    padding: 16px 20px;
  }

  .country-list {
    gap: 10px;
  }

  .country-button {
    padding: 8px 12px;
  }
}
</style>